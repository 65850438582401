<template>
  <section
    class="hotlink page_header_space"
    :style="{ backgroundImage: `url( ${require('@/statics/img/about/bg.png')} )` }"
  >

    <div class="title w1200">
      <h3 class="txt-dark_gray txt-bold ls1">藥庫查詢</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <div class="content w1200">
      <a
        v-for="link in linkArray"
        :key="link.id"
        :href="link.url"
        class="item col50"
        target="_blank"
        rel="noreferrer noopener"
      >
        <div class="col40">
          <img :src="link.img" alt="" class="logo">
        </div>
        <div class="col60">
          <p class="name txt-bold">{{ link.title }}</p>
          <p class="txt-dark_gray small">{{ link.content }}</p>
        </div>
      </a>
    </div>

  </section>
</template>

<script>
import img1 from '@/statics/img/link/logo7.jpg';
import img2 from '@/statics/img/link/logo8.jpg';
import img3 from '@/statics/img/link/logo1.jpg';

export default {
  name: 'HotlinkMedicine',
  data() {
    return {
      linkArray: [
        {
          id: 1,
          url: 'http://drugtw.com/',
          img: img1,
          title: '藥品外觀查詢',
          content: '藥品外觀，對醫療人員及用藥民眾來說，都是很重要的資訊。然而臺灣的藥品外觀資料，散在政府單位、機關團體、公私立醫院，這造成藥品辨識的不方便。',
        },
        {
          id: 2,
          url: 'https://www.taiwan-pharma.org.tw/public/public_search.php',
          img: img2,
          title: '民眾用藥查詢',
          content: '本會以聯合全國藥師，增進藥學學術，發展藥學事業，協助政府推行法令與社會服務，維護會員權益，增進共同利益為宗旨。',
        },
        {
          id: 3,
          url: 'http://www1.nhi.gov.tw/QueryN/Query1.aspx?menu=18&menu_id=703',
          img: img3,
          title: '健保用藥品項查詢',
          content: '標誌是以愛心及人結合，其意涵二代健保更用心、讓您更安心，象徵全民政府對全民醫療照顧之心。人和愛也表達：我愛健保、有你真好；健保是你我的寶，二代健保品質更好的蘊涵。',
        },
      ],
    };
  },
};
</script>
